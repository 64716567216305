import React, { useCallback, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap/dist/gsap';

import style from './ChapterNavigation.module.scss';
import ArrowSVG from './arrow.svg';
import Toggle from './toggle.svg';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useAppContext } from '../../context/AppContext';

export type ChapterNavigationType = {
  // onNext: () => void;
  // onPrevious: () => void;
  onMenu: () => void;
  toggleOnly: boolean;
};

export const ChapterNavigation = ({
  onMenu,
  toggleOnly = false,
}: ChapterNavigationType): JSX.Element => {
  const {
    setCurrentChapter,
    currentChapter,
    setCurrentChapterPage,
    currentChapterPage,
    nextChapterURL,
    prevChapterURL,
    menuIsShowing,
    setCurrentRouteTransition,
    currentRouteTransition,
    chapters,
  } = useAppContext();

  const router = useRouter();

  useEffect(() => {
    if (router.asPath === '/') {
      setCurrentChapter(undefined);
    } else if (router.query.page) {
      setCurrentChapterPage(parseInt(router.query.page.toString()));
    } else {
      setCurrentChapterPage(0);
    }
  }, [router, currentChapter]);

  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.code === 'ArrowRight') {
        onNext();
      }
      if (e.code === 'ArrowLeft') {
        onPrevious();
      }
    };

    window.addEventListener('keydown', onKeyUp);

    return () => {
      window.removeEventListener('keydown', onKeyUp);
    };
  }, [currentChapter, currentChapterPage]);

  const setTransitionToQuick = () => {
    if (currentRouteTransition.type !== 'quick') {
      setCurrentRouteTransition({ type: 'quick', durationMS: 0 });
    }
  };

  const onPrevious = () => {
    setTransitionToQuick();
    if (!currentChapter) return;
    const nextIndex = currentChapterPage - 1;
    if (nextIndex < 0) {
      const chapterBySlug = chapters.find((c) => c.slug === prevChapterURL);
      if (!chapterBySlug) throw Error(`no chapter found with slug:, ${prevChapterURL}`);
      const totalPages = chapterBySlug.page_count - 1;
      router.push(`/chapter/${prevChapterURL}?page=${totalPages}`);
    } else {
      router.push(`/chapter/${currentChapter.slug}?page=${nextIndex}`);
      // setCurrentChapterPage(nextIndex);
    }
  };

  const onNext = () => {
    setTransitionToQuick();

    if (!currentChapter) return;

    const nextIndex = currentChapterPage + 1;
    if (nextIndex > currentChapter.pages.length - 1) {
      router.push(`/chapter/${nextChapterURL}`);
    } else {
      router.push(`/chapter/${currentChapter.slug}?page=${nextIndex}`);
    }
  };

  const isHome = router.asPath === '/';
  const is404 = router.asPath === '/404';
  const menuState = menuIsShowing ? style.toggleSelected : null;
  const nextState = !is404 && !isHome && !menuIsShowing ? style.nextIsVisible : null;
  const prevState = !is404 && !isHome && !menuIsShowing ? style.prevIsVisible : null;

  const toggleClass = toggleOnly ? style.showToggleOnly : style.showToggleAndArrows;
  return (
    <div className={style.menuContainer}>
      {!toggleOnly && (
        <button className={`${style.prev} ${prevState}`} onClick={onPrevious}>
          <ArrowSVG />
        </button>
      )}
      <button className={`${style.toggle} ${menuState} ${toggleClass}`} onClick={onMenu}>
        <Toggle />
      </button>
      {!toggleOnly && (
        <button className={`${style.next} ${nextState}`} onClick={onNext}>
          <ArrowSVG />
        </button>
      )}
    </div>
  );
};
