import { RouterScrollProvider } from '@moxy/next-router-scroll';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap/dist/gsap';

import style from './app.module.scss';
import { AppInner } from '../components/appInner/AppInner';
import { AppContext, AppContextType, TransitionType } from '../context/AppContext';
import { DesktopMenu } from '../components/desktopmenu/DesktopMenu';
import { ChapterNavigation } from '../components/chapterNavigation/ChapterNavigation';
import { ChapterType } from './chapter/[id]';

import '../scss/global.scss';
import Head from 'next/head';
import { isDesktopWidth, setCSSVar } from '../utils/helpers';
import { useWindowSize } from '../hooks/useWindowSize';
import Image from 'next/image';
import { DefaultMediaBlockProps } from '../components/general/blocks/switchBlock/SwitchBlock';
import { MediaBlock } from '../components/general/blocks/mediaBlock/MediaBlock';

function MyApp(props: AppProps) {
  const router = useRouter();
  const [menuIsShowing, setShowMenu] = useState<boolean>(false);
  const [isPreloading, setIsPreloading] = useState<boolean>(false);
  const [preloadItems, setPreloadItems] = useState<DefaultMediaBlockProps[]>([]);
  const [hideUI, setHideUI] = useState<boolean>(false);
  const [currentChapter, setCurrentChapter] = useState<ChapterType | undefined>(undefined);
  const [chapters, setChapters] = useState<ChapterType[]>([]);
  const [currentChapterPage, setCurrentChapterPage] = useState<number>(0);
  const [nextChapterURL, setNextChapterChapterURL] = useState<string>('');
  const [prevChapterURL, setCurrentChapterChapterURL] = useState<string>('');
  const [currentRouteTransition, setCurrentRouteTransition] = useState<TransitionType>({
    type: 'quick',
    durationMS: 0,
  });
  const chapterNavRef = useRef<HTMLDivElement>(null);
  const size = useWindowSize();

  const appContext: AppContextType = {
    showMenu: setShowMenu,
    menuIsShowing: menuIsShowing,
    currentChapter: currentChapter,
    currentChapterPage: currentChapterPage,
    setCurrentChapter: setCurrentChapter,
    setCurrentChapterPage: setCurrentChapterPage,
    nextChapterURL: nextChapterURL,
    prevChapterURL: prevChapterURL,
    setNextChapterURL: setNextChapterChapterURL,
    setPrevChapterURL: setCurrentChapterChapterURL,
    setChapters: setChapters,
    chapters: chapters,
    setHideUI,
    hideUI,
    currentRouteTransition,
    setCurrentRouteTransition,
  };

  const routeChange = () => {
    window.scroll(0, 0);
    setShowMenu(false);

    // Temporary fix to avoid flash of unstyled content
    // during route transitions. Keep an eye on this
    // issue and remove this code when resolved:
    // https://github.com/vercel/next.js/issues/17464

    const tempFix = () => {
      const allStyleElems = document.querySelectorAll('style[media="x"]');
      allStyleElems.forEach((elem) => {
        elem.removeAttribute('media');
      });
    };
    tempFix();
  };

  const onKey = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      setShowMenu(false);
    }
  };

  const onResize = () => {
    setCSSVar('--vh', `${window.innerHeight}px`);
  };

  useEffect(() => {
    if (isPreloading) return;
    if (chapters.length > 0) {
      setIsPreloading(true);
      const mediaItems: DefaultMediaBlockProps[] = [];
      chapters.forEach(({ allMedia }) => {
        allMedia.forEach((mediaItem) => {
          mediaItems.push(mediaItem);
        });
      });
      setPreloadItems(mediaItems);
    }
  }, [chapters, isPreloading, size.width]);

  useEffect(() => {
    if (router.asPath !== '/') {
      gsap.set('.js-menu-list-intro-hook', { clearProps: 'all' });
      document.querySelector('.js-menu-list-intro-hook')?.classList.remove('u-remove-animation');
      document.body.classList.remove('u-bounce-remover');
    }
  }, [router]);

  useEffect(() => {
    onResize();
  }, [size]);

  useEffect(() => {
    gsap.killTweensOf(chapterNavRef.current);
    if (hideUI) gsap.to(chapterNavRef.current, { autoAlpha: 0, display: 'none' });
    if (!hideUI) gsap.to(chapterNavRef.current, { autoAlpha: 1, display: 'block', delay: 0.5 });
  }, [hideUI]);

  useEffect(() => {
    document.addEventListener('keydown', onKey);
    setCSSVar('--menuSlideTime', '1s');
  }, []);

  useEffect(() => {
    document.fonts.ready.then(() => {
      document.body.classList.add('is-fonts-loaded');
    });
    // reset the postion on refresh
    window.scrollTo(0, 0);
    // don't pass anything into dep array, it only needs to run once
  }, []);

  useEffect(() => {
    // https://github.com/vercel/next.js/issues/17464#issuecomment-914561683
    if (router.pathname === '/404') {
      router.push(router.pathname);
    } else {
      router.push(router.asPath);
    }
  }, []);

  const onMenu = () => {
    setShowMenu(!menuIsShowing);
  };

  Router.events.on('routeChangeComplete', routeChange);
  Router.events.on('routeChangeStart', routeChange);

  const toggleState = menuIsShowing ? style.chapterNavMobileIsOpen : null;

  return (
    <div className={style.siteContainer}>
      <Head>
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_HOST}${router.asPath}`}
          key="url"
        />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <meta name="theme-color" content="#ffffff" media="(prefers-color-scheme: light)" />
        <meta name="theme-color" content="#ffffff" media="(prefers-color-scheme: dark)" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <RouterScrollProvider>
        <AppContext.Provider value={appContext}>
          <div className={style.mainMenu}>
            <DesktopMenu slideIn={menuIsShowing} isIntro={router.asPath === '/'} />
          </div>
          <div ref={chapterNavRef} className={`${style.chapterNav}`}>
            <ChapterNavigation onMenu={onMenu} toggleOnly={false} />
          </div>
          <div className={`${style.chapterNavMobile} ${toggleState}`}>
            <ChapterNavigation onMenu={onMenu} toggleOnly={true} />
          </div>
          <div className={style.pages}>
            <AppInner {...props} />
          </div>
        </AppContext.Provider>
      </RouterScrollProvider>
    </div>
  );
}

export default MyApp;
