import React, { useEffect, useRef, useState } from 'react';

import style from './Logo.module.scss';
import { useRouter } from 'next/router';
import Link from 'next/link';
import gsap from 'gsap/dist/gsap';

import { isBrowser } from '../../utils/helpers';
import { MenuList } from '../menuList/MenuList';
import { useAppContext } from '../../context/AppContext';

export type LogoType = { alignLeft: boolean };

export const Logo = ({ alignLeft }: LogoType): JSX.Element => {
  return (
    <div className={`${style.container}`}>
      <p>Anthony</p>
      <p>St</p>
      <p>John</p>
      <p>Parsons</p>
    </div>
  );
};
