import { useRouter } from 'next/router';
import React, { cloneElement, ReactElement, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TransitionType, useAppContext } from '../../../context/AppContext';
import { getCSSVar, setCSSVar } from '../../../utils/helpers';
import styles from './PageTransition.module.scss';

const getTimeout = (animation: TransitionType) => {
  switch (animation.type) {
    case 'quick': {
      return 0;
    }
    default:
      // slightly higher than the animation time in the CSS!
      return 1050; //animation.durationMS + 50;
  }
};
const getZIndex = (animation: TransitionType, inProp: boolean) => {
  switch (animation.type) {
    case 'splitSlide':
    case 'quick':
      return !inProp ? 1 : undefined;
    default:
      return undefined;
  }
};

export type PageTransitionProps = {
  node: ReactElement;
  animation: TransitionType;
  transitioning: boolean;
  style: {};
  in: boolean;
  onEntered: () => void;
  onExited: () => void;
};

export const PageTransition = ({
  node,
  // animation = 'glideLeft',
  transitioning = false,
  style,
  in: inProp = false,
  onEntered,
  onExited,
}: PageTransitionProps) => {
  const router = useRouter();
  const { currentRouteTransition } = useAppContext();

  const handleEntered = (e: any) => {
    document.querySelector('.js-page-swapper')?.classList.remove('u-no-overflow-x');
    onEntered();
  };
  const handleExited = (e: any) => {
    onExited();
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setCSSVar('--pageTransitionTime', `${currentRouteTransition.durationMS}ms`);
  }, [currentRouteTransition]);

  return (
    // <TransitionGroup>
    <CSSTransition
      in={inProp}
      onEntered={handleEntered}
      onExited={handleExited}
      onEnter={() => {
        document.querySelector('.js-page-swapper')?.classList.add('u-no-overflow-x');
      }}
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone,
      }}
      // appear
      timeout={getTimeout(currentRouteTransition)}
    >
      <div
        // key={`${router.asPath}`}
        className={styles[currentRouteTransition.type]}
        // DEV NOTE: for some reason the "top" value is wrong on this project due to padding directly on pages, reset here
        style={{ ...style, top: 0, zIndex: getZIndex(currentRouteTransition, inProp) }}
      >
        {cloneElement(node, { transitioning })}
      </div>
    </CSSTransition>
    // </TransitionGroup>
  );
};
