import { createContext, useContext } from 'react';
import { ChapterType } from '../pages/chapter/[id]';

export type TransitionType = { type: 'quick' | 'splitSlide'; durationMS: number };

export type AppContextType = {
  showMenu: (val: boolean) => void;
  menuIsShowing: boolean;

  setHideUI: (val: boolean) => void;
  hideUI: boolean;

  currentRouteTransition: TransitionType;
  setCurrentRouteTransition: (t: TransitionType) => void;

  currentChapter: ChapterType | undefined;
  setCurrentChapterPage: (f: number) => void;
  setCurrentChapter: (f: ChapterType | undefined) => void;
  currentChapterPage: number;

  nextChapterURL: string;
  prevChapterURL: string;
  setNextChapterURL: (s: string) => void;
  setPrevChapterURL: (s: string) => void;

  chapters: ChapterType[];
  setChapters: (c: ChapterType[]) => void;
};

export const AppContext = createContext<AppContextType>({
  showMenu: (val: boolean) => {},
  menuIsShowing: false,
  currentChapter: undefined,
  currentChapterPage: 0,
  setCurrentChapterPage: () => {},
  setCurrentChapter: () => {},
  setNextChapterURL: () => {},
  setPrevChapterURL: () => {},
  prevChapterURL: '',
  nextChapterURL: '',
  chapters: [],
  setChapters: () => {},
  setHideUI: () => {},
  hideUI: false,
  currentRouteTransition: { type: 'quick', durationMS: 1000 },
  setCurrentRouteTransition: () => {},
});

export const useAppContext = (): AppContextType => useContext(AppContext);
