import React, { useEffect, useRef } from 'react';

import style from './DesktopMenu.module.scss';

import { isBrowser } from '../../utils/helpers';
import { MenuList } from '../menuList/MenuList';
import { useAppContext } from '../../context/AppContext';
import { Logo } from './Logo';
import Link from 'next/link';

if (isBrowser()) {
  // Due to SSR we can only register when we are in the browser
  // gsap.registerPlugin(TextPlugin);
}

export type DesktopMenuType = { slideIn: boolean; isIntro: boolean };

export const DesktopMenu = ({ slideIn, isIntro }: DesktopMenuType): JSX.Element => {
  const { currentChapter, chapters } = useAppContext();
  const listContainerRef = useRef<HTMLDivElement>(null);
  const { setCurrentRouteTransition } = useAppContext();

  // const { data } = useGlobalContentContext();
  useEffect(() => {
    if (slideIn) {
      listContainerRef.current?.scrollTo(0, 0);
      setCurrentRouteTransition({ type: 'splitSlide', durationMS: 1000 });
    }
  }, [slideIn]);

  const animationStateLeft = slideIn || isIntro ? style.leftContainerSlideIn : null;
  const animationStateRight = slideIn
    ? style.listContainerSlideIn
    : isIntro
    ? style.listContainerSlideInIntro
    : null;

  const menuItems = chapters.map((c) => {
    return {
      href: `/chapter/${c.slug}`,
      label: c.title,
      isActive: c.slug === currentChapter?.slug,
    };
  });

  return (
    <div className={`${style.menuContainer}`}>
      <div className={`${style.leftContainer} ${animationStateLeft}`}>
        <div className={style.logoRotator}>
          <Logo alignLeft={true} />
        </div>
        <div className={style.url}>
          {!isIntro ? (
            <Link href="/">
              <a className={style.homeLink}>ASJP.COM.AU</a>
            </Link>
          ) : (
            <span className={style.homeLinkDisabled}>ASJP.COM.AU</span>
          )}
        </div>
        <div className={style.logoRotatorBottom}>
          <Logo alignLeft={false} />
        </div>
      </div>
      <div
        ref={listContainerRef}
        className={`${style.listContainer} ${animationStateRight} js-menu-list-intro-hook`}
      >
        <MenuList items={menuItems} />
        <div className={style.logoMobile}>
          <Link href="/">
            <a className={style.logoMobileLink}>
              <Logo alignLeft={true} />
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};
