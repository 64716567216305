import { useRouterScroll } from '@moxy/next-router-scroll';
import PageSwapper from '@moxy/react-page-swapper';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getPathWithoutQuery } from '../../utils/helpers';

import style from './AppInner.module.scss';
import { PageTransition, PageTransitionProps } from './pageTransition/PageTransition';

export const AppInner = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {}, []);

  const router = useRouter();
  const { updateScroll } = useRouterScroll();

  // create a unique key so we have transitions each time the path changes (also sub id's)
  // we got rid of query params
  const nodeKey = getPathWithoutQuery(router.asPath);

  return (
    <PageSwapper
      className={`${style.pageSwapper} js-page-swapper`}
      updateScroll={updateScroll}
      node={<Component {...pageProps} />}
      animation="glideLeft"
      nodeKey={`${nodeKey}`}
    >
      {(props: PageTransitionProps) => <PageTransition {...props} />}
    </PageSwapper>
  );
};
