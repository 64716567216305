import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import { API_BASE } from '../siteConfig';

// https://stackoverflow.com/questions/8188548/splitting-a-js-array-into-n-arrays
export const splitToChunks = (arrayRaw, parts) => {
  const result = [];
  const array = [...arrayRaw];
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
};

export const formatDate = (d) => {
  return format(parseISO(d), 'MMM dd yyyy');
};

export const getOrientation = (w, h) => {
  if (w == h) return `square`;
  if (w > h) return `landscape`;
  if (w < h) return `portrait`;
};

export const getAPI = () => {
  return API_BASE;
};

export const getDomPos = (selector) => {
  return document.querySelector(selector)?.getBoundingClientRect().x;
};
export const isBrowser = () => {
  return typeof window !== 'undefined';
};

export const isMobileWidth = (w) => {
  if (!w && isBrowser()) {
    return window.innerWidth <= 1124;
  }
  return w <= 1124;
};
export const isDesktopWidth = (w) => {
  if (!isBrowser()) {
    return true;
  }
  return w >= 1200;
};

export const hasTouch = () => document.documentElement.classList.contains('touchevents');

export const isSafari = () => {
  return (
    navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') == -1
  );
};

export const setCSSVar = (varName, value) => {
  document.documentElement.style.setProperty(varName, value);
};
export const getCSSVar = (varName) => {
  if (!isBrowser()) return 0;
  return parseFloat(getComputedStyle(document.documentElement).getPropertyValue(varName));
};

export const isIOS = () => {
  const ua = navigator.userAgent;
  const ios =
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  return ios;
};

export const getHTMLFontSize = () => {
  if (!isBrowser()) return 1;

  const style = window.getComputedStyle(document.documentElement).getPropertyValue('font-size');
  const fontSizeInPixels = parseFloat(style);
  return fontSizeInPixels;
};

export const getGridColumnWidth = (el) => {
  // if we don't pass an el, we treat it as full screenwidth
  if (el) {
    console.warn('needs implementation');
    return 0;
  }

  // get the amount of pixels a col should be
  const pixelPerColumn = window.innerWidth / getCSSVar('--standard-cols');

  return pixelPerColumn;
};

export const pixelToRem = (pixel) => {
  // const getHTMLFontSize
  return pixel / getHTMLFontSize();
};
export const remToPixel = (rem) => {
  // const getHTMLFontSize
  return rem * getHTMLFontSize();
};

export const hasScrolledToBottom = (offset) => {
  return window.innerHeight + Math.ceil(window.pageYOffset) >= document.body.offsetHeight;
};

export const getPathWithoutQuery = (path) => {
  return path.split('?')[0];
};
