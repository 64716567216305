import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap/dist/gsap';

import style from './MenuList.module.scss';
import { useRouter } from 'next/router';
import Link from 'next/link';
import LogoSmall from '../logo/logo-eye.svg';

export type MenuListType = {
  items: { href: string; label: string; isActive: boolean }[];
};

export const MenuList = ({ items }: MenuListType): JSX.Element => {
  const list = useRef<HTMLUListElement>(null);

  const { asPath } = useRouter();

  return (
    <div className={style.menuContainer}>
      <ul ref={list} className={style.list}>
        {items.map((link) => {
          const itemState = link.isActive ? style.listItemLinkActive : null;
          return (
            <li className={`${style.listItem} `} key={link.label}>
              <Link href={link.href}>
                <a className={`${style.listItemLink} ${itemState}`}>{link.label}</a>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
